<template>
  <v-menu
    v-model="isMenuOpened"
    transition="slide-y-transition"
    bottom
    left
    offset-y
    :open-on-hover="!isTabletDevice && !isMobileDevice"
    :open-on-click="isTabletDevice || isMobileDevice"
    close-on-click
    close-on-content-click
  >
    <button
      @focus="onFocusLanguageMenu"
      slot="activator"
      class="language-btn text-no-wrap"
      ref="languageBtn"
      :aria-label="getUILocaleName(language)"
      tabindex="0"
    >
      <icon-base width="22" height="22" class="hidden-sm-and-up language-icon">
        <d-icon-translation />
      </icon-base>
      <span class="body-2 text-capitalize hidden-xs-only button-text">
        {{ getUILocaleName(language) }}
        <v-icon small class="ml-1" color="dBlue" role="presentation">keyboard_arrow_down</v-icon>
      </span>
    </button>
    <div class="list-div">
      <div
        v-for="(lang, idx) in languages"
        :key="idx"
        class="list-item"
        :class="{ 'current-list-item': lang == language }"
        @click="changeLang(lang)"
        @keyup.enter="changeLang(lang)"
        tabindex="0"
      >
        {{ getUILocaleName(lang) }}
      </div>
    </div>
  </v-menu>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconTranslation from "@/components/icons/DIconTranslation.vue";
import { UI_LOCALES, getUILocaleName } from "@/lang/i18n";

export default {
  name: "DAuthHeaderLanguageMenu",
  props: {
    isEmitChangeUrl: Boolean,
    isMobileDevice: Boolean,
    isTabletDevice: Boolean,
  },
  data() {
    return {
      languages: UI_LOCALES,
      language: this.$i18n.locale || "en",
      isMenuOpened: false,
    };
  },
  components: {
    IconBase,
    DIconTranslation,
  },
  methods: {
    onFocusLanguageMenu() {
      this.isMenuOpened = true;
    },
    getUILocaleName,
    changeLang(newLanguage) {
      if (newLanguage == this.language) return;
      this.$i18n.locale = newLanguage;
      this.language = newLanguage;
      localStorage.setItem("locale", newLanguage);
      if (this.isEmitChangeUrl) {
        this.$emit("change-lang-url");
      }
      location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.language-btn {
  position: relative;
  height: 22px;
  color: #ffffff;
  padding-left: 22px;

  &:focus-visible {
    outline: var(--focus-visible-outline);
  }
}
.language-icon {
  color: var(--dLigthGrey400-color);
  position: absolute;
  left: 0;
  top: -1px;
}
.button-text {
  color: var(--dLigthGrey400-color);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-left: 12px;
}
.list-div {
  background-color: #323338;
  border-radius: 2px;
  padding: 6px 0;
}
.list-item {
  color: #ffffff;
  text-align: left;
  padding: 8px 24px 8px 24px;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}
.list-item:hover {
  background-color: #52545d !important;
}
.current-list-item {
  color: #4689f3;
}
</style>
