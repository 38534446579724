<template>
  <div class="auth-wrapper">
    <div class="all-content">
      <d-layout row wrap class="justify-center" style="position: relative">
        <d-flex class="d-flex text-xs-left justify-center align-center xs12 md6 lg5 xl4">
          <v-card flat color="transparent" class="elevation-0 headline-card">
            <div class="headline-box">
              <span class="gradient-text" v-html="$t('auth.authTitleGradientColorText')" />
            </div>
          </v-card>
        </d-flex>

        <d-flex class="xs12 md6 lg5 xl4">
          <!-- email password -->
          <v-card v-if="!isSuccess" flat class="auth-box-lg">
            <div class="auth-box-background">
              <div class="log-in-div text-xs-left">
                <p class="log-in-title">
                  {{ $t("password.createNewPassword") }}
                </p>
                <p class="log-in-subtitle" v-html="$t('password.selectNewPassword')" />
                <v-form v-model="formValid" autocomplete="off">
                  <v-text-field
                    v-model="newPassword"
                    :label="$t('password.newPassword')"
                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    autocomplete="chrome-off"
                    :rules="[rules.validPassword, rules.noWhiteSpace, rules.max, rules.min]"
                    required
                    dark
                    class="input-field pb-2"
                  ></v-text-field>

                  <v-text-field
                    v-model="newPassword2"
                    :label="$t('password.confirmNewPassword')"
                    :append-icon="showPassword2 ? 'visibility' : 'visibility_off'"
                    :type="showPassword2 ? 'text' : 'password'"
                    @click:append="showPassword2 = !showPassword2"
                    autocomplete="chrome-off"
                    :rules="[rules.required]"
                    required
                    dark
                    class="input-field pb-2"
                    @keydown.enter.prevent="setNewPassword"
                  ></v-text-field>

                  <p v-if="newPassword != newPassword2 && newPassword2" class="error-text">
                    {{ $t("password.passwordMustMatch") }}
                  </p>
                </v-form>
              </div>
              <div class="btn-div">
                <button
                  :disabled="!formValid || newPassword != newPassword2"
                  @click="setNewPassword"
                  class="dh-btn btn-primary log-in-btn justify-center"
                >
                  {{ $t("password.confirm") }}
                </button>
              </div>
            </div>
          </v-card>

          <v-card v-else flat class="auth-box-lg">
            <div class="auth-box-background">
              <icon-base height="108" width="108" color="#30548a" class="mt-4 mb-3">
                <d-icon-success />
              </icon-base>
              <p class="create-account-title">{{ $t("all.success") }}!</p>
              <p class="create-account-subtitle" v-html="$t('password.passwordCreatedMsg')">
                <!-- Your new password was created, <br>please wait while we redirect you… -->
              </p>
              <div class="btn-div">
                <button class="dh-btn btn-primary log-in-btn justify-center" @click="goToPlayer">
                  {{ $t("password.goToDeepHowPlayer") }}
                </button>
              </div>
            </div>
          </v-card>
        </d-flex>
      </d-layout>

      <!-- copy right & privacy policy -->
      <div class="copy-right">
        <span v-if="$backendType != 'ali'">
          <span @click="popupContent = 'privacy-policy-en'" class="text-hover">
            {{ $t("all.privacyPolicy") }}
          </span>
          <span> &copy; {{ new Date().getFullYear() }} - DeepHow Corp. {{ $t("auth.allRightReserved") }}</span>
        </span>

        <span v-else>
          <a target="_blank" href="http://beian.miit.gov.cn/">
            <span class="white--text">沪ICP备2020037560号</span>
          </a>
          <span class="white-dot"></span>
          <span> &copy; {{ new Date().getFullYear() }} - DeepHow Corp. {{ $t("auth.allRightReserved") }}</span>
          <span class="white-dot"></span>
          <span @click="popupContent = 'privacy-policy-zh'" class="text-hover-zh">
            {{ $t("all.privacyPolicy") }}
          </span>
          <span class="white-dot"></span>
          <span @click="popupContent = 'user-agreement-zh'" class="text-hover-zh">
            {{ $t("all.userAgreement") }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MixinUser from "./MixinUser.vue";
import IconBase from "./IconBase.vue";
import DeephowLogo from "./icons/logo/DeephowLogo.vue";
import DIconSuccess from "./icons/colored/DIconSuccess.vue";
import UserLanguageMenu from "@/components/DUser/UserLanguageMenu";
import { resetNewPassword } from "@/server/basic-authentication-server.js";
import { i18nKeysDictionary } from "@/js/authentication";
import { loggingError } from "@/server/error-log-server.js";
import { mapMutations } from "vuex";

export default {
  name: "UserNewPassword",
  props: {
    isMobileDevice: Boolean,
    isTabletDevice: Boolean,
  },
  data() {
    return {
      newPassword: "",
      newPassword2: "",
      showPassword: false,
      showPassword2: false,
      email: "",
      token: "",
      rules: {
        noWhiteSpace: (value) => {
          const pattern = /^[^ ]+$/;
          return pattern.test(value) || this.$t("rules.noSpace");
        },
        required: (value) => !!value || this.$t("rules.required"),
        min: (value) => (value && value.length >= 10) || this.$t("rules.min10"),
        max: (value) => (value && value.length <= 25) || this.$t("rules.max25"),
        validPassword: (value) => {
          // Include lower and upper case letters, numbers, and special characters.
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!-\/:-@[-\`{-~]).*$/;
          return pattern.test(value) || this.$t("rules.validPasswordCharacters");
        },
      },
      formValid: false,
      isSuccess: false,
    };
  },
  components: {
    IconBase,
    DeephowLogo,
    DIconSuccess,
    UserLanguageMenu,
  },
  mixins: [MixinUser],
  created() {
    this.token = this.$route.params.token;
    if (this.$route.params.lang) {
      this.$i18n.locale = this.$route.params.lang;
    }
    this.setAuthState();
  },
  mounted() {},
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    changeLangUrl() {
      const newUrl = "/usernewpassword/" + this.$route.params.token + "/" + this.$i18n.locale;
      this.$router.push(newUrl);
    },
    goToPlayer() {
      this.signInWithEmail(this.email, this.newPassword);
    },
    setAuthState() {
      this.auth().onAuthStateChanged((user) => {
        if (user) {
          this.$router.push("/navigator/home");
        }
      });
    },
    async setNewPassword() {
      const newPassword = {
        token: this.token,
        password: encodeURIComponent(this.newPassword),
      };
      try {
        const { data } = await resetNewPassword(newPassword);
        this.email = data.email;
        this.isSuccess = true;
        setTimeout(() => this.goToPlayer(), 1000);
      } catch (error) {
        const i18nKey = i18nKeysDictionary[error.response.data.authType];
        const message = !!i18nKey ? this.$t(`auth.${i18nKey}`) : this.$t("password.alertFailedResetPassword");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
        loggingError(error);
      }
    },
  },
};
</script>

<style scoped>
.auth-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.auth-wrapper:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.all-content {
  background-color: var(--dGrey1-color);
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding-bottom: 204px;
  overflow: hidden;
}
.headline-card {
  max-width: 670px;
  margin: 0 auto;
}
.auth-box-lg {
  width: 100%;
  max-width: 550px;
  min-height: 660px;
  border-radius: 8px;
  opacity: 0.95;
  margin: 0 auto;
  margin-top: 64px;
  background-image: linear-gradient(90deg, #4689f3, #10b981);
  border-radius: 32px;
  padding: 2px;
}
.auth-box-background {
  height: 100%;
  min-height: 660px;
  border-radius: 30px;
  background: var(--dGrey2-color);
}
.log-in-div {
  position: relative;
  padding: 68px;
  padding-bottom: 0;
}
.headline-box {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.gradient-text {
  background: linear-gradient(90deg, #4686f1 14.79%, #10b981 87.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -1.056px;
}
.login-headline {
  color: #ffffff;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -1.056px;
}
.log-in-title {
  display: flex;
  color: #ffffff;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.log-in-subtitle {
  color: var(--dLigthGrey400-color);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 40px;
}
.btn-div {
  padding: 0px 68px;
  padding-bottom: 80px;
  width: 100%;
}
.log-in-btn {
  width: 100%;
}
/* for fixing Chrome auto-filling bug of Vuetify */
.input-field ::v-deep input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.copy-right {
  color: var(--dGrey6-color);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  bottom: 110px;
  width: 100%;
  text-align: center;
}

.white-dot {
  height: 4px;
  width: 4px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px 3px 10px;
}
.text-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}
.text-hover-zh {
  cursor: pointer;
  text-decoration: underline;
}
@media screen and (max-width: 959px) {
  .headline-card {
    margin-top: 64px;
  }
}

.create-account-title {
  color: #ffffff;
  font-family: Roboto;
  font-size: 32px;
  line-height: 44px;
  margin: 0;
}
.create-account-subtitle {
  color: #8d909f;
  font-family: Roboto;
  font-size: 20px;
  line-height: 30px;
}
.success-btn {
  position: relative;
  margin-top: 48px;
}
.error-text {
  color: #888888 !important;
  caret-color: #888888 !important;
  font-size: 12px;
  text-align: left;
  margin-top: -14px;
}
</style>
